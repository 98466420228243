import React, {useCallback, useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import {DataContext} from '../../data-context';
import {
	Color,
	FontSize,
	JOB_DESC,
	NAV_PROJECTS,
	NAV_QUOTES,
	OFFICE_USE,
	PROJECT,
	PROJECT_DETAILS,
	PROJECT_UPDATE_PERM,
	QUOTE,
	OfficeUseOrdering,
	VIEW_PROJECT_DELETE,
	VIEW_QUOTE_DELETE,
	MS_PER_S
} from '../../constants';
import {StyledText} from '../Shared/StyledText';
import {StyledDiv} from '../Shared/StyledDiv';
import {StyledLink} from '../Shared/StyledLink';
import {StyledList} from '../Shared/StyledList';
import {Button, FileButton} from '../Shared/Button';
import {FormattedDate} from '../Shared/FormattedDate';
import {
	ActivityViewButton,
	DeleteButton,
	EditFormButton,
	ApproveButton,
	TimeWidgetButton,
	UploadFileButton,
} from '../Shared/OverlayViewButton';
import {ProjectDetailsHeader} from './ProjectDetailsHeader';
import {MetadataList} from '../Shared/MetadataList';
import {FlexTableController} from '../Shared/FlexTableController';
import {TimeWidget} from '../Shared/TimeWidget';
import {Activity} from '../Activity/Activity';
import {faArrowAltCircleDown, faPlus, faTimes, faTrashAlt} from '@fortawesome/pro-regular-svg-icons';
import {faFileUpload} from '@fortawesome/pro-light-svg-icons';
import {config} from "../../config";
import {API} from "../../projlibs/api";
import {useHistory} from "react-router-dom";
import {CreateProjectsForm} from "../Projects/CreateProjectsForm";
import S3ImageController from "../Shared/S3ImageController";
import {StyledInitials} from "../Shared/StyledInitials";
import {error, success} from '../../projlibs/feedback';
import {DeleteConfirmation} from '../Shared/DeleteConfirmation';
import moment from "moment";
import {convertMinutesToHours} from "../../projlibs/helperFunctions";
import JSZip from 'jszip';
import saveAs from 'file-saver';
import {FileDropzone} from '../Shared/FileDropzone';
import {useDropzone} from 'react-dropzone'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {ApproveProjectForm} from "./ApproveProjectForm";
import { Helmet } from 'react-helmet';

// ProjectDetails: display project details
// Props:
// project_id: project_id for selected project.
const ProjectDetailsView = styled.div``;

const MainView = styled.div`
	margin: 0px 24px 24px 24px;
`;

const MetaDataListView = styled.div`
	display: flex;
	max-height: unset;
	flex-flow: column wrap;
	align-content: flex-start;
	box-sizing:border-box;
	@media (min-width: 600px) {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 1rem;
	}
	@media (min-width: 1200px) {
		grid-template-columns: repeat(3, 1fr);
	}
`;

const FileListView = styled.div`
	background-color: ${Color.white};
	margin-top: 1rem;
	border-top: 0.25rem solid ${Color.fuel};
	border-radius: 3px 3px 0 0;
	box-shadow: 0rem 0.0625rem 0.375rem ${Color.nile15};
`;

const HeaderView = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: fit-content;
	border-bottom: 0.0625rem solid ${Color.nile10};
`;

const FooterView = styled.div`
	height: 5.25rem;
	border-top: 0.0625rem solid ${Color.nile10};
	display: flex;
	justify-content: center;
	padding-top: 1.5rem;
`;

const ProjectDetailsNavigationAndControls = styled.div`
	display: flex;
	flex-flow: column nowrap;
	justify-content: space-between;
	align-items: center;
	padding: 2rem 0 2rem 1.5rem;
	@media (min-width: 600px) {
		flex-flow: row nowrap;
	}
`;

const ProjectDetailsControls = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	flex-flow: row nowrap;
	align-items: center;
	@media (min-width: 600px) {
		justify-content: flex-end;	
	}
`;

const ProjectEditControls = styled.div`
	display: flex;
	flex-flow: row wrap;
	align-items: baseline;
	padding-top: 1rem;
	@media (min-width: 600px) {
		padding-top: 0;
		align-items: unset;
		flex-flow: row nowrap;
	}
`;

const ResponsiveDeleteButton = styled(DeleteButton)`
	@media (max-width: 600px) {
		width: fit-content;
		padding-left: 0;
		margin: 0 0.5rem 1rem 0;
		flex: 0 1 calc(50% - 1rem);
	}
`;

const ResponsiveEditButton = styled(EditFormButton)`
	@media (max-width: 600px) {
		margin: 0 0.5rem 1rem 0;
		flex: 0 1 calc(50% - 1rem);
	}
`;

const ResponsiveTimeButton = styled(TimeWidgetButton)`
	@media (max-width: 600px) {
		margin: 0;
		flex: 0 1 calc(50% - 1rem);
	}
`;

const ResponsiveApproveButton = styled(ApproveButton)`
	@media (max-width: 600px) {
		margin: 0 0 1rem 0.5rem;
		flex: 0 1 calc(50% - 1rem);
	}
`;

const FileButtonsContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items:center;
	width:400px;
	margin: 8px 8px 8px 8px;
	@media (min-width: 500px) {
		flex-direction: row;
	}
`;

const ResponsiveFileButton = styled(FileButton)`
	@media (max-width: 500px) {
		margin:0;
	};
`;

const ResponsiveUploadButton = styled(UploadFileButton)`
	@media (max-width: 500px) {
		margin:16px 0 0 0;
	};
`;

const DropzoneInput = styled.input`
    border: none;
`;

const DropzoneText = styled(StyledText)`
    color: ${Color.nile70};
    padding-top: 1rem;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
`;

const UploadIcon = styled(FontAwesomeIcon)`
    align-self: center;
`;

const InnerContainer = styled.div`
    display: flex;
    flex-direction: column;
	padding: 2.5rem 1rem;
    margin-top: auto;
    margin-bottom: auto;
`;

const DropzoneContainer = styled.div`
    display: flex;
    flex-direction: column;
	outline: none;
`;

const FileWrapper = styled.div`
	padding:1.75rem 1.5rem 1rem;
	flex-grow: 1;
	display: flex;
	align-items: center;
	flex-flow: row nowrap;
`;

function DeleteFile(project_id, file_id, fetchFiles, pageSize) {
	API.put(`/project/${project_id}/file/${file_id}`, { data: { valid: false } }).then(() => fetchFiles(pageSize, project_id));
}

function SetupTableData(files, context, project_id, fetchFiles, pageSize) {

	let tableData = {
		titles: ['Name', 'Size', 'Uploaded', 'Uploaded By', ''],

		rows: files.File?.map((file, index) => {
			return [
				{
					sortValue: file.file_name,
					element: (
						<Button title={file.file_name}
								color={Color.nile}
								textDecorationLine='underline'
								onClick={() => downloadFile(context, file.s3_path)}/>
					),
				},
				{
					sortValue: file.file_size,
					element: <StyledText>{Math.round(file.file_size / MS_PER_S)} KB </StyledText>,
				},
				{
					sortValue: file.modified_at,
					element: (
						<FormattedDate
							date={file.modified_at}
							format='MMM DD, YYYY'
							color={Color.nile50}
						/>
					),
				},
				{
					sortValue: file.User.full_name,
					element: (
						<StyledDiv display='flex' contentAlign="left">
							{file.User.img_s3_path ?
								<S3ImageController
									s3_path={file.User.img_s3_path}
									width='24px'
									height='24px'
									borderRadius='50%'
								/>
								:
								<StyledInitials
									size='24px'
									marginTop='0'
									marginBottom='0'
									fontSize={FontSize.body1}
									borderRadius='50%'
									backgroundColor={Color.zest}
									numberOfInitials={2}>
									{file.User.full_name}
								</StyledInitials>
							}
							<StyledText
								fontSize={FontSize.body2}
								color={Color.nile50}
								marginLeft='4px'>
								{file.User.full_name}
							</StyledText>
						</StyledDiv>
					),
				},
				{
					element: (
						<DeleteButton icon={faTrashAlt} selectedIconColor={Color.nile50} >
							<DeleteConfirmation onDelete={() => {DelteFile(project_id, file.file_id, fetchFiles, pageSize)}} />
						</DeleteButton>
					),
				},
			];
		}),
	};

	return tableData;
}

export const calcTotalPrice = (retailPrice, deliveryPrice, setupFee, actualHours, hourlyRate, discount) =>
	(((1 - (discount / 100)) * (retailPrice + deliveryPrice + setupFee)) + (actualHours * hourlyRate)).toFixed(2)

const downloadFile = (context, s3_path) => {
	context.aws.getSignedUrl(config.bucket_name, s3_path, API.default_error_handler, success => {
		window.open(success);
	})
}

const fetchFile = (context, file) => {
	return new Promise((resolve, reject) => {
		context.aws.getSignedUrl(config.bucket_name, file.s3_path, err => {
			API.default_error_handler(err);
			reject(err);
		}, success => {
			fetch(success).then(data => resolve({
				name: file.file_name,
				content: data.blob(),
			})).catch(reject);
		});
	});
}

const downloadAll = (context, files, projectId) => {
	if(files.File !== undefined) {
		const fetchedFiles = files.File.map(file => fetchFile(context, file));
		const zip = new JSZip();
		Promise.all(fetchedFiles).then(downloadedFiles => {
			downloadedFiles.forEach(file => zip.file('Project_'+projectId+'_files/'+file.name, file.content));
			zip.generateAsync({ type: 'blob' }).then(content => saveAs(content, `Project_${projectId}_files.zip`))
		}).catch(() => error('Sorry we were unable to download the files you requested.'));
	}
}
export function ApproveQuote(history, quoteId, type, status) {
	const params = {data: {
		is_quote: false,
		project_type: type,
		project_status:status,
	}};
	API.put(`/quote/${quoteId}`, params).then(() => {
		history.push(NAV_PROJECTS);
		success('Quote approved.');
	}).catch(API.default_error_handler);
}

function UnapproveProject(history, quoteId, type, status) {
	const params = {data: {
			is_quote: true,
			project_type: type,
			project_status:status,
		}};
	API.put(`/quote/${quoteId}`, params).then(() => {
		history.push(NAV_QUOTES);
		success('Project unapproved.');
	}).catch(API.default_error_handler);
}

function SortNetworkMetaData(response){
	let projects=response?.data?.Project;
	if(projects && projects.length>0){
		for(let proj_idx=0;proj_idx<projects.length;proj_idx++){
			let project_meta=projects[proj_idx].project_meta;
			if(project_meta && (project_meta.length>0)){
				project_meta=project_meta.sort(function (a,b) {
					if(a.display_order<b.display_order){
						return -1;
					}else if(a.display_order>b.display_order){
						return 1;
					}
					return 0;
				});
				response.data.Project[proj_idx].project_meta=project_meta;
			}
		}
	}

	return response;
}

export const ProjectDetails = (props) => {
	const [fileDropIsUploading, setFileDropIsUploading] = useState(false);
	const endpoint = props.isQuote ? 'quote' : 'project';
	const history = useHistory();
	const context = useContext(DataContext);
	const [files, setFiles] = useState({});
	const [allFiles, setAllFiles] = useState({});
	const resultSize = files.result_count;
	const [pageSize, setPageSize] = useState(7);
	const [data, setData] = useState({});
	const [isInvalidId, setIsInvalidId] = useState(false);
	const [notes, setNotes] = useState([]);

	const quoteStatuses = context.quoteStatuses;


	useEffect(() => {
		API.get(`/${endpoint}/${props.project_id}`).then(response => {
			response=SortNetworkMetaData(response);
			if (response.data.Project) {
				setData(response.data);
			} else {
				let otherEndpoint = endpoint === 'quote' ? 'project' : 'quote';
				API.get(`/${otherEndpoint}/${props.project_id}`).then(response => {
					response=SortNetworkMetaData(response);
					if (response.data.Project) {
						history.push(`${otherEndpoint === 'quote' ? NAV_QUOTES : NAV_PROJECTS}/${props.project_id}`)
					} else {
						setIsInvalidId(true);
					}
				}).catch(API.default_error_handler);
			}
		}).catch(API.default_error_handler);
	}, [endpoint, history, props.project_id]);

	useEffect(() => {
		API.get('project/'+props.project_id+'/note').then(response => {
			const notes = response.data?.Note ? response.data.Note : [];
			setNotes(notes);
		});
	}, [props.project_id,setNotes]);

	const UpdateData = () => {
		API.get(`/${endpoint}/${props.project_id}`).then(response => {
			response=SortNetworkMetaData(response);
			setData(response.data);
		});
	};

	let project = data?.Project ? data.Project[0] : undefined;
	const statuses = data?.Project ? context.projectStatuses[data.Project[0].Project_Type.name] : [];

	const onDrop = useCallback(acceptedFiles => {
		setFileDropIsUploading(true);
		const HandleFile = (file) => {
			if (file) {
				const path = 'project/' + project.project_id + '/' + file.name;
				context.aws.uploadFile(config.bucket_name, path, file, () => {
					setFileDropIsUploading(false);
					error(`Error trying to upload file: ${file.name}`);
				}, success => {
					// if the file is large and has to upload in parts then large aws saves it in the variable Key instead of key
					let key = success.Key ? success.Key : success.key;
					SavePath(key, file);
				});
			}
		};
		const SavePath = (key, file) => {
			const fileToSend = {
				s3_path: key,
				file_name: file.name,
				file_size: file.size,
				uploaded_by: context.userId,
			}
			API.post(`/project/${project.project_id}/file`, { data: fileToSend }).then(() => {
				success(`File: ${file.name} successfully uploaded`);
				setFileDropIsUploading(false);
				fetchFiles(pageSize, project.project_id);
			}).catch(err => {
				setFileDropIsUploading(false);
				API.default_error_handler(err);
			});
		}
		acceptedFiles.forEach(file => HandleFile(file));
	}, [context.aws, context.userId, setFileDropIsUploading, pageSize, project]);
	
	const {getRootProps, getInputProps, isDragActive} = useDropzone({ onDrop });

	const fetchFiles = (pageSize, projectId) => {
		//update the displayed file list in the table
		API.get(`/project/${projectId}/file?page_size=${pageSize}`).then(({ data }) => setFiles(data));
		
		//update the complete file list which is used for the "download all" function
		//this includes files which aren't on the first page of results
		//which is why it's a separate request
		API.get(`/project/${projectId}/file?page_size=`).then(({ data }) => setAllFiles(data));
	};

	useEffect(() => {
		fetchFiles(pageSize, props.project_id);
	}, [pageSize, props.project_id]);

	const DeleteProject = () => {
		API.put(`/project/${props.project_id}`, { data: { valid: false } }).then(() => {
			success('Project successfully deleted.');
			const returnTo = props.isQuote ? NAV_QUOTES : NAV_PROJECTS;
			history.push(returnTo);
		}).catch(API.default_error_handler);
	}

	let projectDetailCard = {};
	let officeUseCard = {};
	let metadata = [];
	if (project) {
		metadata = project.project_meta;
		projectDetailCard = {
			"meta_name": "Project Details",
			"project_meta_id": 0,
			"meta_value": [
				{
					"label": "Date Quoted",
					"value": project.date_quoted ? moment.unix(project.date_quoted).utc().format('MMM. DD, YYYY') : '-',
				},
				{
					"label": "Customer Contact",
					"value": project.Contact?.name,
				},
				{
					"label": "Customer Email",
					"value": project.Contact?.email,
				},
				{
					"label": "Date Created",
					"value": project.created_at ? moment.unix(project.created_at).utc().format('MMM. DD, YYYY') : '-',
				}
			]
		}

		officeUseCard = {
			"meta_name": "Office Use",
			"project_meta_id": 1,
			"meta_value": [
				{
					"label": "Retail Price",
					"value": project.retail_price ? "$" + project.retail_price : "-",
				},
				{
					"label": "Delivery Price",
					"value": project.delivery_price ? "$" + project.delivery_price : "-",
				},
				{
					"label": "Setup Fee",
					"value": project.setup_fee ? "$" + project.setup_fee : "-",
				},
				{
					"label": "Discount",
					"value": (project.discount ? project.discount : '0') + "%",
				},
				{
					"label": "Actual Hours",
					"value": project.actual_hours,
				},
				{
					"label": "Hourly Rate",
					"value": project.hourly_rate ? "$" + project.hourly_rate : "-",
				},
				{
					"label": "Total Price",
					"value": "$" + calcTotalPrice(project.retail_price, project.delivery_price, project.setup_fee, project.actual_hours, project.hourly_rate, project.discount),
				},
				{
					"label": "Total Hours Logged",
					"value": convertMinutesToHours(project.total_logged_mins),
				}
			]
		}
		if(metadata.find(data => data.meta_name === OFFICE_USE)){
			officeUseCard.meta_value = [...metadata.find(data => data.meta_name === OFFICE_USE).meta_value, ...officeUseCard.meta_value];
			officeUseCard.meta_value = officeUseCard.meta_value.sort((metaA, metaB) => {
				let orderA = OfficeUseOrdering[metaA.label];
				let orderB = OfficeUseOrdering[metaB.label];
				if (orderA && orderB) {
					return orderA > orderB ? 1 : -1;
				}
				if (orderA && !orderB) {
					return 1;
				}
				if (!orderA && orderB) {
					return -1;
				}
				return metaA.label > metaB.label ? 1 : -1;
			});
			metadata = metadata.filter(data => data.meta_name !== OFFICE_USE)
		}
		if(metadata.find(data => data.meta_name === PROJECT_DETAILS)){
			projectDetailCard.meta_value.push(...metadata.find(data => data.meta_name === PROJECT_DETAILS).meta_value)
			metadata = metadata.filter(data => data.meta_name !== PROJECT_DETAILS)
		}
		metadata = [officeUseCard, projectDetailCard, ...metadata];

		if (project.project_meta.some(meta => meta.meta_name === PROJECT_DETAILS)) {
			metadata =
				metadata.sort((metaA, metaB) => {
				let metaASortValue = metaA.meta_name;
				let metaBSortValue = metaB.meta_name;
				if (metaA.meta_name === PROJECT_DETAILS) {
					metaASortValue = 'A';
				}
				if (metaA.meta_name === JOB_DESC) {
					metaASortValue = 'B';
				}
				if (metaA.meta_name === OFFICE_USE) {
					metaASortValue = 'C';
				}
				if (metaB.meta_name === PROJECT_DETAILS) {
					metaBSortValue = 'A';
				}
				if (metaB.meta_name === JOB_DESC) {
					metaBSortValue = 'B';
				}
				if (metaB.meta_name === OFFICE_USE) {
					metaBSortValue = 'C';
				}
				return metaASortValue > metaBSortValue ? 1 : -1;
			});
		} else {
			metadata = [projectDetailCard, ...project.project_meta];
		}

	}

	return (
		<ProjectDetailsView>
			<Helmet>
				{
					project &&
					<title>{`${project?.project_id} | ${project?.name}`}</title>
				}
				<meta name="description" content={project?.name} />
			</Helmet>
			<ProjectDetailsNavigationAndControls>
				<StyledList
					width='100%'
					marginTop='auto'
					marginBottom='auto'
					alignSelf='start'
					display='flex'
					fontSize={FontSize.body1}
					listStyle='none'
					content='/'>
					<StyledLink to={props.isQuote ? NAV_QUOTES : NAV_PROJECTS}>{props.isQuote ? 'Quotes' : 'Projects'}</StyledLink>
					<StyledText color={Color.nile50}>
						{project?.name}
					</StyledText>
				</StyledList>
				<ProjectDetailsControls>
					<ProjectEditControls>
						{
							context.userPerms[PROJECT_UPDATE_PERM] &&
							<>
								{
									(props.isQuote ? context.userPerms[VIEW_QUOTE_DELETE] : context.userPerms[VIEW_PROJECT_DELETE]) &&
									<ResponsiveDeleteButton title={props.isQuote ? 'Delete quote' : 'Delete project'}>
										<DeleteConfirmation onDelete={DeleteProject} />		
									</ResponsiveDeleteButton>
								}
								<ResponsiveEditButton>
									<CreateProjectsForm project={data.Project} isQuote={props.isQuote} update={UpdateData}/>
								</ResponsiveEditButton>
							</>
						}
						<ResponsiveTimeButton>
							<TimeWidget project={project}/>
						</ResponsiveTimeButton>
						{
							context.userPerms[PROJECT_UPDATE_PERM] &&
							(props.isQuote ?
								<ResponsiveApproveButton title='Approve'>
									<ApproveProjectForm approve statuses={statuses} approveQuote={(status) => ApproveQuote(history, props.project_id, data.Project[0].Project_Type.name, status)} id={props.project_id}/>
								</ResponsiveApproveButton>
								:
								<ResponsiveApproveButton title='Unapprove' icon={faTimes}>
									<ApproveProjectForm unapprove statuses={quoteStatuses} unapproveProject={(status) => UnapproveProject(history, props.project_id, data.Project[0].Project_Type.name, status)} id={props.project_id}/>
								</ResponsiveApproveButton>
							)
						}
					</ProjectEditControls>
					<ActivityViewButton notes={notes}>
						<Activity projectId={props.project_id} name={project?.name} type={props.isQuote ? QUOTE : PROJECT}/>
					</ActivityViewButton>
				</ProjectDetailsControls>
			</ProjectDetailsNavigationAndControls>
			<MainView>
				{
					isInvalidId &&
					<StyledText
						width='fit-content'
						marginLeft='auto'
						marginRight='auto'
						fontSize={FontSize.body1}
						color={Color.cheeto}
					>
						This resource could not be found, are you sure it exists?
					</StyledText>
				}
				{
					(statuses && statuses.length > 0 && project) &&
					<ProjectDetailsHeader updateProject={UpdateData} quoteStatuses={quoteStatuses} statuses={statuses} project={project} is_quote={props.isQuote}/>
				}
				{ metadata.length > 0 &&
					<MetaDataListView>
						{metadata?.map((metadataList) => (
							<MetadataList
								{...metadataList}
								key={metadataList.project_meta_id}
							/>
						))}
					</MetaDataListView>
				}

				{project !== undefined &&
				<FileListView>
					<HeaderView>
						<FileWrapper>
							<StyledText color={Color.nile} fontSize={FontSize.header5}>Files</StyledText>
							{
								<StyledText fontSize={FontSize.header5} color={Color.nile} alignSelf='flex-start'> ({resultSize})</StyledText>
							}
						</FileWrapper>
						{
							resultSize > 0 &&
							<StyledText fontSize={FontSize.body2} color={Color.nile50} alignSelf='flex-start'>Drop file(s) to upload, or use the buttons</StyledText>
						}
						<FileButtonsContainer>
							<ResponsiveFileButton
								title='Download All'
								icon={faArrowAltCircleDown}
								width='100%'
								padding='0'
								onClick={() => downloadAll(context, allFiles, project.project_id)}
							/>
							{
								context.userPerms[PROJECT_UPDATE_PERM] &&
								<ResponsiveUploadButton
									width='100%'
									padding='0'
									disabled={fileDropIsUploading}
									title={fileDropIsUploading ? 'Uploading...' : 'Add Files'}
									icon={faPlus}
								>
									<FileDropzone updateFiles={() => fetchFiles(pageSize, project.project_id)} projectId={project.project_id} />
								</ResponsiveUploadButton>
							}
						</FileButtonsContainer>
					</HeaderView>
					<DropzoneContainer {...getRootProps({ onClick: event => resultSize === 0 ? null : event.stopPropagation() })}>
						<FlexTableController tableData={SetupTableData(files, context, props.project_id, fetchFiles, pageSize)} />
						<DropzoneInput {...getInputProps()} />
						{
							resultSize === 0 &&
							<InnerContainer>
								<UploadIcon
									color={Color.nile70}
									icon={faFileUpload}
									size='6x'
								/>
								{
									isDragActive ?
									<DropzoneText>Drop the files here ...</DropzoneText> :
									<DropzoneText>Drag 'n' drop some files here, or click to select files</DropzoneText>
								}
							</InnerContainer>
						}
					</DropzoneContainer>
					{pageSize < resultSize &&
						<FooterView>
							<Button
								height='42px'
								width='296px'
								title='Load More'
								color={Color.nile}
								borderColor={Color.nile15}
								onClick={() => setPageSize(pageSize + 5)}
							/>
						</FooterView>
					}
				</FileListView> }
			</MainView>
		</ProjectDetailsView>
	);
};

ProjectDetails.defaultProps = {
	className: 'ProjectDetails',
};
