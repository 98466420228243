import React from 'react';
import styled from 'styled-components';
import {Color, FontSize, FontWeight} from '../../constants';
import {StyledText} from '../Shared/StyledText';
import {StyledInitials} from '../Shared/StyledInitials';
import {OldLinkView} from '../Shared/OldLinkView';
import {ProjectType} from '../Shared/ProjectType';
import {Button} from "../Shared/Button";
import {breakpoint} from '../../breakpoints';

// ClientDetailsHeader: display the header in ClientDetails
// Props:
// client: Client object with many fields used to set the various UI elements.
const ClientDetailsHeaderView = styled.div`
	display: flex;
	flex-direction: column;
	margin: 0px 24px 16px 24px;
	${breakpoint('medium up')} {
		margin: 0px 24px 16px 24px;
	}
`;

const TitleText = styled(StyledText)``;
TitleText.defaultProps = {
	color: Color.nile50,
	fontSize: FontSize.body2,
	marginBottom: '6px',
};

const ContactButton = styled(Button)``;
ContactButton.defaultProps = {
	borderTop: 0,
	borderRight: 0,
	borderLeft: 0,
	borderColor: Color.nile15,
	lineHeight: '1',
	borderStyle: 'dotted',
	backgroundColor: Color.transparent,
	hoverColor: Color.poppy,
	fontWeight: FontWeight.semibold,
	color:Color.nile70,
	fontSize:FontSize.body1,
	paddingLeft: 0,
	paddingRight: 0,
	paddingBottom: '2px',
};

const DataText = styled(StyledText)``;
DataText.defaultProps = {
	color: Color.nile,
	fontSize: FontSize.body1,
};

const NameContainer = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 24px;
`;

const BodyContainer = styled.div`
	display: flex;
	flex-direction: column;
	min-width: unset;
	background-color: ${Color.white};
	box-shadow: 0px 1px 6px ${Color.nile15};
	${breakpoint('medium up')} {
		min-width: fit-content;
	}
`;

const TopContainer = styled.div`
	display: flex;
	flex-direction: column;
	border-bottom: 1px solid ${Color.mischka};
	${breakpoint('medium up')} {
		flex-direction: row;
	}
`;

const PaddedContainer = styled.div`
	padding: 24px;
`;

const TopContainer1 = styled(PaddedContainer)`
	width: calc(100% - 48px);
	${breakpoint('medium up')} {
		border-right: 1px solid ${Color.mischka};
		width: fit-content;
	}
`;

const TopContainer2 = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	${breakpoint('medium up')} {
		border-right: 1px solid ${Color.mischka};
	}
	@media (min-width: 480px) {
		flex-direction: row;
	}
`;

const TopContainer2Left = styled(PaddedContainer)`
	width: calc(100% - 48px);
	${breakpoint('medium up')} {
		width: fit-content;
	}
`;

const TopContainer2Right = styled(PaddedContainer)`
	width: calc(100% - 48px);
	${breakpoint('medium up')} {
		width: fit-content;
	}
`;

const TopContainer2RightName = styled.div`
	display: flex;
`;

const TopContainer3 = styled(PaddedContainer)`
	width: calc(100% - 48px);
	border-right: 1px solid ${Color.mischka};
	${breakpoint('medium up')} {
		width: fit-content;
	}
`;

const TopContainer4 = styled(PaddedContainer)`
	width: calc(100% - 48px);
	${breakpoint('medium up')} {
		width: fit-content;
		min-width: 50px;
	}
`;

const BottomContainer = styled.div`
	display: flex;
	flex-direction: column;
	@media (min-width: 480px) {
		flex-direction: row;
	}
`;

const BottomContainer1 = styled(PaddedContainer)`
	width: calc(100% - 48px);
	@media (min-width: 480px) {
		width: 30%;
	}
`;

const BottomContainer2 = styled(PaddedContainer)`
	width: calc(100% - 48px);
	@media (min-width: 480px) {
		width: 30%;
	}
`;

const BottomContainer3 = styled(PaddedContainer)`
	width: calc(100% - 48px);
	@media (min-width: 480px) {
		width: 40%;
	}
`;

export const ClientDetailsHeader = (props) => (
	<ClientDetailsHeaderView>
		<NameContainer>
			<StyledInitials
				borderRadius='4px'
				backgroundColor={props.client?.color}
				marginRight='16px'>
				{props.client?.name}
			</StyledInitials>
			<StyledText as='h1' color={Color.nile} fontSize={FontSize.header2}>
				{props.client?.name}
			</StyledText>
		</NameContainer>

		<BodyContainer>
			<TopContainer>
				<TopContainer1>
					<TitleText>Account Rep</TitleText>
					<TopContainer2RightName>
						<StyledInitials backgroundColor={props.client?.Account_Rep?.colour ? props.client?.Account_Rep?.colour : Color.zest} marginRight='16px'>
							{props.client?.Account_Rep?.full_name}
						</StyledInitials>
						<DataText>{props.client?.Account_Rep?.full_name}</DataText>
					</TopContainer2RightName>
				</TopContainer1>
				<TopContainer2>
					<TopContainer2Left>
						<TitleText>Primary contact</TitleText>
						<DataText marginBottom='38px'>
							{props.client?.Primary_Contact?.name}
						</DataText>
						<ContactButton title="See all contacts" onClick={props.switchToContacts}/>
					</TopContainer2Left>
					<TopContainer2Right>
						<TitleText>Phone</TitleText>
						<DataText
							as='a'
							href={`tel:client.phone`}
							marginBottom='16px'>
							{props.client?.Primary_Contact?.phone}
						</DataText>
						<TitleText>Email</TitleText>
						<DataText as='a' href={`mailto:client.phone`}>
							{props.client?.Primary_Contact?.email}
						</DataText>
					</TopContainer2Right>
				</TopContainer2>
			</TopContainer>
			<BottomContainer>
				<BottomContainer1>
					<TitleText>Address</TitleText>
					<DataText fontSize={FontSize.header5}>
						{props.client?.Primary_Location?.address}
					</DataText>
				</BottomContainer1>
				<BottomContainer2>
					<TitleText>Website</TitleText>
					<DataText>{props.client?.website}</DataText>
				</BottomContainer2>
			</BottomContainer>
		</BodyContainer>
		{
			typeof(props.client?.old_client_url)==='string' &&
			<OldLinkView>
				This is an imported client; the original data is at <br />
				<a href={(props.client&&props.client.old_client_url)?props.client.old_client_url:''} target='_blank' rel='noopener noreferrer'>
					{props.client?.old_client_url}
				</a>
			</OldLinkView>
		}
	</ClientDetailsHeaderView>
);
