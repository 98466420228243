import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {FormDropdown, FormInput} from '../Shared/CreateForm';
import {breakpoint} from '../../breakpoints';
import {StyledText} from "../Shared/StyledText";
import {Color, FontSize} from "../../constants";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {InvisibleInput} from "../Shared/Input";
import {FileUploadContainer, ResponsiveFileButton, UPLOAD_IMAGE_MIME_TYPES} from "../Users/CreateUserForm";

const CompanyContact = styled.div`
    display:flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    ${breakpoint('small only')} {
		flex-direction: column
	}
`;

export const ContactFields = (props) => {
    const [data, setData] = useState(props.getContact(props.index));
    const [fileMessage, setFileMessage] = useState("");

    useEffect(() => {
        setData(props.getContact(props.index));
    }, [props]);

    const updateContactInfo = (value, id) => {
        let newData = {...data}
        newData[id] = value;
        props.updateContact(newData, props.index);
        setData(newData);
    };

    const setFileObject = (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        if(!file){
            return false;
        }
        let newData = {...data};
        newData["image"] = file;
        props.updateContact(newData, props.index);
        setData(newData);
        setFileMessage(file.name);
    };

    return (
        <CompanyContact>
            <FormInput
                type='text'
                label={props.index === 0 ? 'Primary Contact Name*' : 'Contact Name*'}
                inputId={'name' + props.index}
                flex = {props.isBasic ? '0 0 49%' : '0 0 49%'}
                placeholder='...'
                value={data.name ? data.name : ''}
                onChange={(e) => {
                    updateContactInfo(e.target.value, 'name');
                }}
                required
            />
            <FormInput
                type='text'
                label='Title'
                inputId={'title' + props.index}
                flex = '0 0 49%'
                placeholder='...'
                value={data.title ? data.title : ""}
                onChange={(e) => {
                    updateContactInfo(e.target.value, 'title');
                }}
            />

            <FormInput
                type='email'
                label='Email*'
                inputId={'email' + props.index}
                flex = '0 0 49%'
                placeholder='...'
                value={data.email ? data.email : ""}
                onChange={(e) => {
                    updateContactInfo(e.target.value, 'email');
                }}
                required
            />
            <FormInput
                type='text'
                label='Phone'
                inputId={'phone' + props.index}
                flex = '0 0 49%'
                placeholder='...'
                value={data.phone ? data.phone : ""}
                onChange={(e) => {
                    updateContactInfo(e.target.value, 'phone');
                }}
            />
        </CompanyContact>
    )
};

