import React, {useContext, useState, useEffect} from 'react';
import styled, {css} from 'styled-components';
import {Color, FontSize, FontWeight, USER_READ_PERM} from '../../constants';
import {Button} from '../Shared/Button';
import {AlertCell} from './AlertCell';
import {StyledText} from '../Shared/StyledText';
import {StyledBase} from '../Shared/StyledBase';
import {faArrowLeft, faArrowRight} from '@fortawesome/pro-regular-svg-icons';
import {DataContext} from '../../data-context';
import { UserSelect } from '../Shared/UserSelect';
import { useGetData, API } from '../../projlibs/api';
import { getField, getNewValue } from '../Activity/Logs';

// AlertList: display list of alerts
// Props:
// alerts: array of alerts to display.
// loadMoreOnClick: LoadMore button onClick callback
const AlertListView = styled.div`
	background-color: ${Color.white};
	padding: 1.5rem 1.5rem;
	overflow-y: auto;
	border-top: 0.25rem solid ${Color.fuel};
	border-radius: 3px 3px 0 0;
	${props => css`
		${StyledBase}
		padding-left: ${props.isOverlay ? (props.location === 'right' ? '1.5rem' : '5rem') : '1.5rem'};
		padding-right: ${props.isOverlay ? (props.location === 'right' ? '5rem' : '1.5rem') : '1.5rem'};
		height: ${props.isOverlay ? 'calc(100% - 3rem)' : 'fit-content'};
	`}
	box-shadow: 0rem 0.0625rem 0.375rem ${Color.nile15};
`;

const HeaderView = styled.h2`
	text-align: start;
	font-size: ${FontSize.header5};
	${props => css`
		font-size: ${props.isOverlay ? FontSize.header3 : FontSize.header5};
		border-bottom: ${props.disableBorder ? '0' : `0.0625rem solid ${Color.nile10}`};
	`}
	padding-bottom: 1.5rem;
	margin: 0rem;
`;

const StatusListView = styled.div`
	overflow: hidden;
`;

const FooterView = styled.div`
	display: flex;
	justify-content: center;
	padding-top: 1.5rem;
`;

const NoAlertsText = styled(StyledText)`
	${props => css`
		padding-top: ${props.hasDropdown ? '0' : '1rem'};
	`}
	color: ${Color.regent};
	font-weight: ${FontWeight.normal};
`;

const normalizeUserData = (data) => {
	return data?.User ? data.User.map((user, index) => ({
		name: user.full_name,
		value: `${user.user_id}`,
		photo: user.img_s3_path,
	})) : [];
}

const normalizeActivityData = (data, projectTypes, userData, projectStatuses, clientStatuses) => {
	const activityPromises = data?.Activity ? data.Activity.map(activity => {
		const name = activity.project_activity ? activity.project_activity[0]?.Project?.name : null;
		const id = activity.project_activity ? activity.project_activity[0]?.project_id : null;
		const userId = activity.user_id;
		const user = userData?.User?.find(user => user.user_id === userId);
		const username = user?.full_name;
		const field = getField(activity);
		return getNewValue(activity, name, projectTypes, userData, projectStatuses, clientStatuses, id).then(value => ({
			id: activity.activity_id,
			title: field.includes('meta.') ?
			(<StyledText fontWeight={FontWeight.normal} display="inline-block" fontSize={FontSize.body1}
						color={Color.nile}>
				<b>{username}</b> changed {field.replace(/meta\..*\./, '')} to {value?.includes('<p>') ? <span className='inner-html' dangerouslySetInnerHTML={{__html: value}} /> : <b>{value}</b>} on <b>{name}</b>
			</StyledText>)
			:
			(<StyledText fontWeight={FontWeight.normal} display="inline-block" fontSize={FontSize.body1}
						color={Color.nile}>
				<b>{username}</b> changed {field} to {value?.includes('<p>') ? <span className='inner-html' dangerouslySetInnerHTML={{__html: value}} /> : <b>{value}</b>} on <b>{name}</b>
			</StyledText>),
			date: activity.modified_at,
			attach: {
				url: `/projects/${id}`,
				name: name,
			},
			overdue: false,
			isNotification: true,
		})).catch(API.default_error_handler);
	}) : [];
	return Promise.all(activityPromises);
};

export const AlertList = (props) => {
	const { alerts, userId, userPerms, getAlertsByUserId } = useContext(DataContext);
	const [selectedAlerts, setSelectedAlerts] = useState(alerts);
	const [selectedNotifications, setSelectedNotifications] = useState([]);
	const [alertPageSize, setAlertPageSize] = useState(5);
	const [notificationPageIndex, setNotificationPageIndex] = useState(0);
	const [notificationResultSize, setNotificationResultSize] = useState(0);
	const [selectedUser, setSelectedUser] = useState(userId);
	const [{ data: projectTypes }] = useGetData("/project/type");
	const [{ data: projectStatuses }] = useGetData("/project/status");
	const [{ data: clientStatuses }] = useGetData("/client/status");
	const [{ data: userData }] = useGetData('/user?page_size=');
	const users = normalizeUserData(userData);
	useEffect(() => {
		if (projectTypes.length === 0 || userData.length === 0 || projectStatuses.length === 0 || clientStatuses.length === 0) {	
			return;
		}
		if (selectedUser === userId) {
			setAlertPageSize(5);
			setNotificationPageIndex(0);
			setSelectedAlerts(alerts);
			API.get(`/activity?page_size=5&page=0`).then(({ data: activityData }) => {
				setNotificationResultSize(activityData.result_count);
				normalizeActivityData(activityData, projectTypes, userData, projectStatuses, clientStatuses).then(activities => setSelectedNotifications(activities));
			}).catch(API.default_error_handler);
		} else {
			setAlertPageSize(5);
			setNotificationPageIndex(0);
			getAlertsByUserId(selectedUser, 5, 0).then(alerts => setSelectedAlerts(alerts)).catch(API.default_error_handler);
			API.get(`/user/${selectedUser}/activity?page_size=5&page=0`).then(({ data: activityData }) => {
				console.log(activityData);
				setNotificationResultSize(activityData.result_count);
				normalizeActivityData(activityData, projectTypes, userData, projectStatuses, clientStatuses).then(activities => setSelectedNotifications(activities));
			}).catch(API.default_error_handler);
		}
	}, [selectedUser, userId, alerts, projectTypes, userData, projectStatuses, clientStatuses, getAlertsByUserId]);

	const loadMoreNotifications = () => {
		API.get(`/user/${selectedUser}/activity?page_size=5&page=${notificationPageIndex + 1}`).then(({ data: activityData }) => {
			setNotificationPageIndex(notificationPageIndex + 1);
			setNotificationResultSize(activityData.result_count);
			normalizeActivityData(activityData, projectTypes, userData, projectStatuses, clientStatuses).then(activities => setSelectedNotifications([...selectedNotifications, ...activities]));
		}).catch(API.default_error_handler);
	};
	return (
		<AlertListView {...props}>
			{
				props.isOverlay &&
				<Button
					zIndex={1}
					icon={props.location === 'right' ? faArrowRight : faArrowLeft}
					iconSize='2x'
					onClick={props.onClose}
					borderStyle='none'
					position='absolute'
					paddingTop='0.5rem'
					paddingRight='0.5rem'
					paddingLeft='0.5rem'
					paddingBottom='0.5rem'
					left={props.location === 'right' ? 'unset' : '1rem'}
					right={props.location === 'right' ? '1rem' : 'unset'}
					top='1.25rem'
					backgroundColor='transparent'
					iconColor={Color.nile}
				/>
			}
			<HeaderView disableBorder isOverlay={props.isOverlay}>{props.isUnified ? 'Notifications & Alerts' : 'My Alerts'}</HeaderView>
			{
				props.isUnified && userPerms[USER_READ_PERM] &&
				<UserSelect
					paddingTop='1rem'
					options={users ? users : []}
					onChange={e => {
						if (!(e.target?.value || e.target?.value === '')) {
							setSelectedUser(e);
						}
					}}
					flex='100%'
					value={selectedUser}
					title='Filter by User'
					placeholder='select user'
				/>
			}
			{
				props.isUnified &&
				<StyledText fontSize={FontSize.header5}>Alerts</StyledText>
			}
			<hr style={{ margin: 0, borderTop: `1px solid ${Color.mischka}`, borderBottom: 'none' }}></hr>
			<StatusListView>
				{
					selectedAlerts.length === 0 &&
					<NoAlertsText>No Alerts to Display</NoAlertsText>
				}
				{selectedAlerts.slice(0, alertPageSize).map((alert) => (
					<AlertCell isEditable={selectedUser === userId} alert={alert} key={alert.id} />
				))}
			</StatusListView>
			{
				selectedAlerts.length > alertPageSize &&
				<FooterView>
					<Button
						title='Load More'
						color={Color.nile}
						borderColor={Color.nile15}
						onClick={() => setAlertPageSize(alertPageSize + 5)}
					/>
				</FooterView>
			}
			{
				props.isUnified &&
				<>
					<StyledText paddingTop='1.5rem' fontSize={FontSize.header5}>Notifications</StyledText>
					<hr style={{ margin: 0, borderTop: `1px solid ${Color.mischka}`, borderBottom: 'none' }}></hr>
					<StatusListView>
						{
							selectedNotifications.length === 0 &&
							<NoAlertsText>No Notifications to Display</NoAlertsText>
						}
						{
							selectedNotifications.map((notification) => (
								<AlertCell alert={notification} key={notification.id} />
							))
						}
					</StatusListView>
					{
						selectedNotifications.length < notificationResultSize &&
						<FooterView>
							<Button
								title='Load More'
								color={Color.nile}
								borderColor={Color.nile15}
								onClick={() => loadMoreNotifications()}
							/>
						</FooterView>
					}
				</>
			}
		</AlertListView>
	);
};
