import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {
    Color,
    FontSize,
    SERVICE_EXPIRY,
    SERVICE_FREQUENCY,
    SERVICE_NAME,
    SERVICE_PRIMARY,
    SERVICE_RATE,
    SERVICE_RENEWAL,
    SERVICE_STATUS,
    SERVICE_TYPE
} from '../../constants';
import {FlexTableController} from '../Shared/FlexTableController';
import {StyledText} from '../Shared/StyledText';
import moment from "moment";
import {PageController} from '../Shared/PageController';
import {PageIndex} from '../Shared/PageIndex';
import {SearchInput} from '../Shared/SearchInput';
import {CreateFormButton, DeleteButton} from '../Shared/OverlayViewButton';
import {faCheck, faEdit, faPlus, faTimes} from '@fortawesome/pro-regular-svg-icons';
import {FilterControlsWrapper, TableHeader} from '../Projects/Projects';
import {CreateServiceForm} from './CreateServiceForm';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from '../Shared/Button';
import {API} from '../../projlibs/api';
import {success} from '../../projlibs/feedback';
import {DeleteConfirmation} from '../Shared/DeleteConfirmation';

const ServiceView = styled.div`
	background-color: ${Color.white};
`;

const ServiceListView = styled.div`
    display: flex;
    flex-direction: column;
`;

const NoResultsText = styled.h3`
	color: ${Color.nile50};
	align-self: center;
	text-align: center;
`;

const CreateButtonContainer = styled.div`
    width: fit-content;
    align-self: flex-end;
    padding: 1rem 1rem 0 0;
`;

const EditButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

const SetupTableData = (services, SubmitForm, DeleteService) => {
    let tableData = {
        titles: [
            SERVICE_TYPE,
            SERVICE_NAME,
            SERVICE_RENEWAL,
            SERVICE_EXPIRY,
            SERVICE_RATE,
            SERVICE_FREQUENCY,
            SERVICE_PRIMARY,
            SERVICE_STATUS,
            '',
        ],
        rows: services.map((service) => {
            return [
                {
                    sortValue: service.type,
                    element: (
                        <StyledText
                            color={Color.nile}
                            fontSize={FontSize.body1}>
                            {service.type}
                        </StyledText>
                    ),
                },
                {
                    sortValue: service.name,
                    element: (
                        <a target='_blank' rel='noopener noreferrer' href={`//${service.name}`}>
                            <StyledText
                                color={Color.nile}
                                fontSize={FontSize.body1}>
                                {service.name}
                            </StyledText>
                        </a>
                    ),
                },
                {
                    sortValue: service.renewal,
                    element: (
                        <StyledText
                            color={Color.nile}
                            fontSize={FontSize.body1}>
                            {moment.unix(service.renewal).utc().format('MMM DD, YYYY')}
                        </StyledText>
                    ),
                },
                {
                    sortValue: service.expiry,
                    element: (
                        <StyledText
                            color={Color.nile}
                            fontSize={FontSize.body1}>
                            {service.expiry ? moment.unix(service.expiry).utc().format('MMM DD, YYYY') : ""}
                        </StyledText>
                    ),
                },
                {
                    sortValue: service.rate,
                    element: (
                        <StyledText
                            color={Color.nile}
                            fontSize={FontSize.body1}>
                            ${service.rate}
                        </StyledText>
                    ),
                },
                {
                    sortValue: service.frequency,
                    element: (
                        <StyledText
                            color={Color.nile}
                            fontSize={FontSize.body1}>
                            {service.frequency}
                        </StyledText>
                    ),
                },
                {
                    sortValue: service.is_primary,
                    element: (service.is_primary ? <FontAwesomeIcon icon={faCheck} /> : null),
                },
                {
                    sortValue: service.status,
                    element: (
                        <StyledText
                            color={Color.nile}
                            fontSize={FontSize.body1}>
                            {service.status}
                        </StyledText>
                    ),
                },
                {
                    element: (
                        <EditButtonsContainer>
                            <CreateFormButton width='40px' iconXOffset='0' marginRight='1rem' icon={faEdit}>
                                <CreateServiceForm service={service} onSubmit={SubmitForm} />
                            </CreateFormButton>
                            <DeleteButton
                                width='40px'
                                iconXOffset='0'
                                icon={faTimes}
                                iconColor={Color.white}
                                backgroundColor={Color.zest}
                                hoverBackground={Color.fuel}
                            >
				    <DeleteConfirmation onDelete={() => DeleteService(service.web_service_id) } />
			    </DeleteButton>
                        </EditButtonsContainer>
                    ),
                },
            ];
        }),
    };
    return tableData;
}

export const Services = (props) => {
    const [services, setServices] = useState([]);
    const [search, setSearch] = useState('');
    const SubmitForm = (service, mode) => {
        if(isNaN(service.expiry)){
            delete service.expiry;
        }
        if (mode === 'edit') {
            API.put(`/client/${props.clientId}/service/${service.id}`, { data: service }).then(() => {
                success('Successfully edited web service');
                fetchServices();
            }).catch(API.default_error_handler);
        } else {
            API.post(`/client/${props.clientId}/service`, { data: service }).then(() => {
                success('Successfully created web service');
                fetchServices();
            }).catch(API.default_error_handler);
        }
    };
    const DeleteService = (serviceId) => {
        API.put(`/client/${props.clientId}/service/${serviceId}`, { data: { is_active: false } }).then(() => {
            success('Successfully deleted web service');
            fetchServices();
        }).catch(API.default_error_handler);
    }
    useEffect(() => {
        API.get(`/client/${props.clientId}/service`).then(({ data }) => {
            const webServices = data?.Web_Service ? data.Web_Service : [];
            setServices(webServices);
        }).catch(API.default_error_handler);
    }, [props.clientId]);

    const fetchServices = () => {
        API.get(`/client/${props.clientId}/service`).then(({ data }) => {
            const webServices = data?.Web_Service ? data.Web_Service : [];
            setServices(webServices);
        }).catch(API.default_error_handler);
    }
    return (
        <ServiceView>
            <ServiceListView>
                <CreateButtonContainer>
                    <CreateFormButton title='New Service' icon={faPlus}>
                        <CreateServiceForm onSubmit={SubmitForm} />
                    </CreateFormButton>
                </CreateButtonContainer>
                <TableHeader>
                    <FilterControlsWrapper>
                        <SearchInput
                            containerStyle={{ flex: '1', minWidth: '200px', width: '100%', margin: '0.5rem 0rem 0.5rem 0.75rem' }}
                            placeholder='Search services'
                            borderColor={Color.nile15}
                            hoverBorderColor={Color.nile5}
                            value={search}
                            onChange={e => setSearch(e.target.value)}
                        />
                    </FilterControlsWrapper>
                    <PageIndex resultCount={services.length} currentPage={0} />
                </TableHeader>
                <FlexTableController
                    tableData={SetupTableData(services.filter(service => service.name.toLowerCase().includes(search.toLowerCase())), SubmitForm, DeleteService)}
                />
                {
                    services.length === 0 &&
                    <NoResultsText>No Services</NoResultsText>
                }
                <PageController pageNumber={0} numPages={services.length > 0 ? 1 : 0} />
            </ServiceListView>
        </ServiceView>
    );
};
