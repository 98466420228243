import React, { useState, useEffect, useContext } from 'react';
import styled, { css } from 'styled-components';
import { Color, FontSize, SEARCH_BASE_PROJECT, SEARCH_BASE_QUOTE, SEARCH_BASE_CLIENT, SEARCH_PAGE_SIZE, SEARCH_BASE_NOTE, PROJECT, CLIENT, CONTACT, PROJECT_READ_PERM, CLIENT_READ_PERM, NOTE_READ_PERM } from '../../constants';
import { Button, UnderlineLink } from './Button';
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { PulseLoader } from 'react-spinners';
import { SearchInput } from './SearchInput';
import { ProjectSearches } from './Search/ProjectSearches';
import { ClientSearches } from './Search/ClientSearches';
import { NoteSearches } from './Search/NoteSearches';
import { StyledBase } from './StyledBase';
import { StyledText } from './StyledText';
import { breakpoint } from '../../breakpoints';
import { API } from '../../projlibs/api';
import { removeHtmlTags } from '../../projlibs/helperFunctions';
import { DataContext } from '../../data-context';

const SearchView = styled.div`
	${StyledBase}
	min-width: unset;
	min-height: unset;
	width: 100%;
	height: 100%;
	background-color: ${Color.white};
	position: relative;
	${breakpoint('medium up')} {
		min-width: 44rem;
		min-height: 100vh;
		width: unset;
		height: unset;
	}
`;

const MainView = styled.div`
	${StyledBase}
	height: calc(100vh - 1rem);
	overflow-y: auto;
	${props => css`
		padding: ${props.location === 'right' ? '1rem 3rem 1rem 1rem' : '1rem 1rem 1rem 3rem'};
		${breakpoint('medium up')} {
			padding: ${props.location === 'right' ? '2rem 5.5rem 2rem 2rem' : '2rem 2rem 2rem 5.5rem'};
		}
	`}
`;

const SectionView = styled.div`
	${StyledBase}
	height: fit-content;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	padding-top: 5rem;
	padding-bottom: 1.5rem;
`;

const SectionTitleText = styled(StyledText)`
	color: ${Color.ghost};
	font-size: ${FontSize.body1};
	padding-bottom: 0.5rem;
	padding-left: 1rem;
`;

const SearchInputContainerStyle = {
	width: '100%',
	margin: '0 0rem 0 1rem',
	borderRadius: '4px',
};

const ResultContainerStyle = {
	minHeight: 0,
	paddingBottom: '0.5rem',
};

const SearchBoxView = styled.div`
	position: absolute;
	width: calc(100% - 100px);
	top: 0;
	left: 0;
	z-index: 3;
	background-color: ${Color.white};
	${props => css`
		padding: ${props.location === 'right' ? '2.25rem 3rem 1.25rem 1rem' : '2.25rem 1.5rem 1.25rem 3rem'};
		${breakpoint('medium up')} {
			width: 83%;
			padding: ${props.location === 'right' ? '2.25rem 4.5rem 1.25rem 1.5rem' : '2.25rem 1.5rem 1.25rem 4.5rem'};
		}
	`}
`;

const SpinnerView = styled.div`
	${StyledBase}
	align-self: center;
`;

const NoResultsText = styled.h4`
	color: ${Color.nile70};
`;

const ShowMoreLinkStyling = {
	width: 'fit-content',
	margin: '0.5rem 0 0.5rem 1rem',
};

const generateSearchQuery = (searchValue, pageIndex, baseUrl) => {
	const matcher = baseUrl === SEARCH_BASE_NOTE ? `match_desc=${searchValue}` : `match_name=${searchValue}`;
	return `${baseUrl}/search?${matcher}&page=0&page_size=${(pageIndex + 1) * SEARCH_PAGE_SIZE}&order_by=created_at:desc`;
};

const normalizeProjectData = (projects) => {
	return projects ? projects.map(project => ({
		id: project.project_id,
		name: project.name,
		status: project.Project_Status?.name,
		clientName: project.Client?.name ? project.Client?.name : 'Client Name',
	})) : [];
};

const normalizeClientData = (clients) => {
	return clients ? clients.map(client => ({
		clientId: client.client_id,
		clientName: client.name,
		clientColor: client.color ? client.color : Color.regent,
	})) : [];
};

const normalizeNoteData = (notes) => {
	return notes ? notes.map(note => {
		let refId = null;
		let name = null;
		let type = null;
		if (note.projects.length > 0) {
			type = PROJECT;
			refId = note.projects[0].project_id;
			name = note.projects[0].name;
		} else if (note.clients.length > 0) {
			type = CLIENT;
			refId = note.clients[0].client_id;
			name = note.clients[0].name;
		} else if (note.contacts.length > 0) {
			type = CONTACT;
			refId = note.contacts[0].contact_id;
			name = note.contacts[0].name;
		}
		return {
			id: note.note_id,
			refId: refId,
			name: name,
			type: type,
			date: note.created_at,
			note: removeHtmlTags(note.description),
			header: note.User ? note.User?.full_name : 'Missing User Name',
		};
	}) : [];
};

export const SearchWidget = ({ onClose, location = 'left' }) => {
	const { userPerms } = useContext(DataContext);
	// We need to keep track of the last submitted search in case the user searches again with the same search string
	const [searchValue, setSearchValue] = useState('');
	const [lastSubmittedSearch, setLastSubmittedSearch] = useState('');

	const [projectPageIndex, setProjectIndex] = useState(0);
	const [projectsLoading, setProjectsLoading] = useState(false);
	const [projectResultSize, setProjectResultSize] = useState(0);
	const [totalProjects, setTotalProjects] = useState([]);

	const [quotePageIndex, setQuoteIndex] = useState(0);
	const [quotesLoading, setQuotesLoading] = useState(false);
	const [quoteResultSize, setQuoteResultSize] = useState(0);
	const [totalQuotes, setTotalQuotes] = useState([]);

	const [clientPageIndex, setClientIndex] = useState(0);
	const [clientsLoading, setClientsLoading] = useState(false);
	const [clientResultSize, setClientResultSize] = useState(0);
	const [totalClients, setTotalClients] = useState([]);

	const [notePageIndex, setNoteIndex] = useState(0);
	const [notesLoading, setNotesLoading] = useState(false);
	const [noteResultSize, setNoteResultSize] = useState(0);
	const [totalNotes, setTotalNotes] = useState([]);

	useEffect(() => {
		if (userPerms[PROJECT_READ_PERM]) {
			setProjectsLoading(true);
			API.get(generateSearchQuery(lastSubmittedSearch, projectPageIndex, SEARCH_BASE_PROJECT)).then(({ data }) => {
				setProjectResultSize(data.result_count);
				setTotalProjects(normalizeProjectData(data.Project));
				setProjectsLoading(false);
			}).catch(err => {
				setProjectsLoading(false);
				API.default_error_handler(err);
			});
		}
	}, [projectPageIndex, lastSubmittedSearch, userPerms]);

	useEffect(() => {
		if (userPerms[PROJECT_READ_PERM]) {
			setQuotesLoading(true);
			API.get(generateSearchQuery(lastSubmittedSearch, quotePageIndex, SEARCH_BASE_QUOTE)).then(({ data }) => {
				setQuoteResultSize(data.result_count);
				setTotalQuotes(normalizeProjectData(data.Project));
				setQuotesLoading(false);
			}).catch(err => {
				setQuotesLoading(false);
				API.default_error_handler(err);
			});
		}
	}, [quotePageIndex, lastSubmittedSearch, userPerms]);

	useEffect(() => {
		if (userPerms[CLIENT_READ_PERM]) {
			setClientsLoading(true);
			API.get(generateSearchQuery(lastSubmittedSearch, clientPageIndex, SEARCH_BASE_CLIENT)).then(({ data }) => {
				setClientResultSize(data.result_count);
				setTotalClients(normalizeClientData(data.Client));
				setClientsLoading(false);
			}).catch(err => {
				setClientsLoading(false);
				API.default_error_handler(err);
			});
		}
	}, [clientPageIndex, lastSubmittedSearch, userPerms]);

	useEffect(() => {
		if (userPerms[NOTE_READ_PERM]) {
			setNotesLoading(true);
			API.get(generateSearchQuery(lastSubmittedSearch, notePageIndex, SEARCH_BASE_NOTE)).then(({ data }) => {
				setNoteResultSize(data.result_count);
				setTotalNotes(normalizeNoteData(data.Note));
				setNotesLoading(false);
			}).catch(err => {
				setNotesLoading(false);
				API.default_error_handler(err);
			});
		}
	}, [notePageIndex, lastSubmittedSearch, userPerms]);

	const handleSearchChange = (event) => {
		setSearchValue(event.target.value);
	};

	const handleSearchSubmit = (event) => {
		event.preventDefault();
		if (searchValue === lastSubmittedSearch) {
			return;
		}
		setProjectIndex(0);
		setQuoteIndex(0);
		setClientIndex(0);
		setNoteIndex(0);
		setTotalClients([]);
		setTotalProjects([]);
		setTotalQuotes([]);
		setTotalNotes([]);
		setLastSubmittedSearch(searchValue);
	};

	const handleShowMoreProjects = () => {
		setProjectIndex(projectPageIndex + 1);
	};

	const handleShowMoreQuotes = () => {
		setQuoteIndex(quotePageIndex + 1);
	};

	const handleShowMoreClients = () => {
		setClientIndex(clientPageIndex + 1);
	};

	const handleShowMoreNotes = () => {
		setNoteIndex(notePageIndex + 1)
	};

	return (
		<SearchView>
			<Button
				zIndex={12}
				icon={location === 'right' ? faArrowRight : faArrowLeft}
				iconSize='2x'
				onClick={onClose}
				borderStyle='none'
				position='absolute'
				paddingTop='0.5rem'
				paddingRight='0.5rem'
				paddingLeft='0.5rem'
				paddingBottom='0.5rem'
				left={location === 'right' ? 'unset' : '1rem'}
				right={location === 'right' ? '1rem' : 'unset'}
				top='2rem'
				backgroundColor='transparent'
				iconColor={Color.nile}
			/>
			<MainView location={location}>
				<SearchBoxView location={location}>
					<SearchInput
						width='100%'
						caretColor={Color.nova}
						containerStyle={SearchInputContainerStyle}
						value={searchValue}
						onChange={handleSearchChange}
						onSubmit={handleSearchSubmit}
						placeholder='Search for Clients, Projects and more'
					/>
				</SearchBoxView>
				<SectionView>
					{
						userPerms[PROJECT_READ_PERM] &&
						<>
							<SectionTitleText>PROJECTS</SectionTitleText>
							{
								totalProjects.length > 0 ?
								<ProjectSearches {...ResultContainerStyle} projects={totalProjects} closeSearch={onClose}/> :
								<NoResultsText>No Results</NoResultsText>
							}
							<SpinnerView>
								<PulseLoader loading={projectsLoading}/>
							</SpinnerView>
							{
								((projectPageIndex + 1) * SEARCH_PAGE_SIZE < projectResultSize) &&
								<UnderlineLink
									title='Show More'
									{...ShowMoreLinkStyling}
									unicodeIcon='\f0d7'
									underline='underline'
									onClick={handleShowMoreProjects}
								/>
							}
						</>
					}
					{
						userPerms[PROJECT_READ_PERM] &&
						<>
							<SectionTitleText>QUOTES</SectionTitleText>
							{
								totalQuotes.length > 0 ?
								<ProjectSearches {...ResultContainerStyle} isQuotes projects={totalQuotes} closeSearch={onClose}/> :
								<NoResultsText>No Results</NoResultsText>
							}
							<SpinnerView>
								<PulseLoader loading={quotesLoading}/>
							</SpinnerView>
							{
								((quotePageIndex + 1) * SEARCH_PAGE_SIZE < quoteResultSize) &&
								<UnderlineLink
									title='Show More'
									{...ShowMoreLinkStyling}
									unicodeIcon='\f0d7'
									underline='underline'
									onClick={handleShowMoreQuotes}
								/>
							}
						</>
					}
					{
						userPerms[CLIENT_READ_PERM] &&
						<>
							<SectionTitleText>CLIENTS</SectionTitleText>
							{
								totalClients.length > 0 ?
								<ClientSearches {...ResultContainerStyle} clients={totalClients} closeSearch={onClose}/> :
								<NoResultsText>No Results</NoResultsText>
							}
							<SpinnerView>
								<PulseLoader loading={clientsLoading}/>
							</SpinnerView>
							{
								((clientPageIndex + 1) * SEARCH_PAGE_SIZE < clientResultSize) &&
								<UnderlineLink
									title='Show More'
									{...ShowMoreLinkStyling}
									unicodeIcon='\f0d7'
									underline='underline'
									onClick={handleShowMoreClients}
								/>
							}
						</>
					}
					{
						userPerms[NOTE_READ_PERM] &&
						<>
							<SectionTitleText>NOTES</SectionTitleText>
							{
								totalNotes.length > 0 ?
								<NoteSearches {...ResultContainerStyle} notes={totalNotes} closeSearch={onClose}/> :
								<NoResultsText>No Results</NoResultsText>
							}
							<SpinnerView>
								<PulseLoader loading={notesLoading}/>
							</SpinnerView>
							{
								((notePageIndex + 1) * SEARCH_PAGE_SIZE < noteResultSize) &&
								<UnderlineLink
									title='Show More'
									{...ShowMoreLinkStyling}
									unicodeIcon='\f0d7'
									underline='underline'
									onClick={handleShowMoreNotes}
								/>
							}
						</>
					}
				</SectionView>
			</MainView>
		</SearchView>
	);
};
