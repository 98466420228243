import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Color, FontSize, SERVICE_TYPE_LIST, SERVICE_FREQUENCY_LIST, SERVICE_TYPE, SERVICE_NAME, SERVICE_RENEWAL, SERVICE_EXPIRY, SERVICE_RATE, SERVICE_FREQUENCY, SERVICE_PRIMARY, SERVICE_STATUS, MS_PER_S} from '../../constants';
import {StyledText} from '../Shared/StyledText';
import {breakpoint} from '../../breakpoints';
import {FlexTableController} from '../Shared/FlexTableController';
import {date} from '../Shared/FormattedDate';
import {PageController} from '../Shared/PageController';
import {API} from '../../projlibs/api';
import downloadCsv from 'download-csv';
import {ReportHeader} from './ReportHeader';
import {PageIndex} from '../Shared/PageIndex';
import moment from 'moment';
import {updateSorting} from "./Reports";
import {FormDropdown} from "../Shared/CreateForm";
import { SearchInput } from '../Shared/SearchInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';

const MONTHS = [
    'Any',
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];

const MONTH_VALUES = ['0','1','2','3','4','5','6','7','8','9','10','11','12'];

const CustomizeReportView = styled.div`
	padding: 0.5rem 1.5rem;
`;

const ProjectSelectionView = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    ${breakpoint('medium up')} {
        flex-direction: row;
    }
`;

const ReportToolbar = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    ${breakpoint('medium up')} {
        flex-direction: row;
    }
`;

const ResponsiveFormDropdown = styled(FormDropdown)`
    max-width: 260px;
    ${breakpoint('small down')} {
        max-width: unset;
        margin: 0;
    }
`;

const SetupTableData = (services) => {
    let tableData = {
        titles: [
            'Client',
            SERVICE_TYPE,
            SERVICE_NAME,
            SERVICE_RENEWAL,
            SERVICE_EXPIRY,
            SERVICE_RATE,
            SERVICE_FREQUENCY,
            SERVICE_PRIMARY,
            SERVICE_STATUS,
        ],
        rows: services.map((service) => {
            return [
                {
                    sortValue: service.type,
                    element: (
                        <StyledText
                            color={Color.nile}
                            fontSize={FontSize.body1}>
                            {service.Client?.name}
                        </StyledText>
                    ),
                },
                {
                    sortValue: service.type,
                    element: (
                        <StyledText
                            color={Color.nile}
                            fontSize={FontSize.body1}>
                            {service.type}
                        </StyledText>
                    ),
                },
                {
                    sortValue: service.name,
                    element: (
                        <a target='_blank' rel='noopener noreferrer' href={`//${service.name}`}>
                            <StyledText
                                color={Color.nile}
                                fontSize={FontSize.body1}>
                                {service.name}
                            </StyledText>
                        </a>
                    ),
                },
                {
                    sortValue: service.renewal,
                    element: (
                        <StyledText
                            color={Color.nile}
                            fontSize={FontSize.body1}>
                            {moment.unix(service.renewal).utc().format('MMM DD, YYYY')}
                        </StyledText>
                    ),
                },
                {
                    sortValue: service.expiry,
                    element: (
                        <StyledText
                            color={Color.nile}
                            fontSize={FontSize.body1}>
                            {moment.unix(service.expiry).utc().format('MMM DD, YYYY')}
                        </StyledText>
                    ),
                },
                {
                    sortValue: service.rate,
                    element: (
                        <StyledText
                            color={Color.nile}
                            fontSize={FontSize.body1}>
                            ${service.rate}
                        </StyledText>
                    ),
                },
                {
                    sortValue: service.frequency,
                    element: (
                        <StyledText
                            color={Color.nile}
                            fontSize={FontSize.body1}>
                            {service.frequency}
                        </StyledText>
                    ),
                },
                {
                    sortValue: service.is_primary,
                    element: (service.is_primary ? <FontAwesomeIcon icon={faCheck} /> : null),
                },
                {
                    sortValue: service.status,
                    element: (
                        <StyledText
                            color={Color.nile}
                            fontSize={FontSize.body1}>
                            {service.status}
                        </StyledText>
                    ),
                },
            ];
        }),
    };
    return tableData;
}

const generateWebServiceUrl = (billingMonth, frequency, serviceType, searchQuery, pageIndex = 0, pageSize = 10, orderBy) => {
    let url = `/client/service/search?page=${pageIndex}&page_size=${pageSize}`;
    if (billingMonth !== '0') {
        url += `&match_month=${billingMonth}`;
    }
    if (frequency) {
        url += `&match_frequency=${frequency}`;
    }
    if (serviceType) {
        url += `&match_type=${serviceType}`;
    }
    if (searchQuery) {
        url += `&match_name=${searchQuery}`;
    }
    if (orderBy) {
        url +=`&${orderBy}`;
    }
    return url;
};

export const WebServicesReport = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [persistSearchQuery, setPersistSearchQuery] = useState('');
    const [billingMonth, setBillingMonth] = useState('0');
    const [persistBillingMonth, setPersistBillingMonth] = useState();
    const [frequency, setFrequency] = useState('');
    const [persistFrequency, setPersistFrequency] = useState('');
    const [serviceType, setServiceType] = useState('');
    const [persistServiceType, setPersistServiceType] = useState('');
    const [pageIndex, setPageIndex] = useState(0);
    const [webServices, setWebServices] = useState([]);
    const [resultCount, setResultCount] = useState(0);
    const [orderBy, setOrderBy] = useState("");
    const handleGenerate = () => {
        setPersistBillingMonth(billingMonth);
        setPersistFrequency(frequency);
        setPersistServiceType(serviceType);
        setPersistSearchQuery(searchQuery);
    };
    const handleCSV = () => {
        if (resultCount !== 0) {
            API.get(generateWebServiceUrl(persistBillingMonth, persistFrequency, persistServiceType, persistSearchQuery, 0, '', orderBy)).then(response => {
                const results = response.data.Web_Service ? response.data.Web_Service : [];
                const csvData = results.map(result => ({
                    "Id": `${result.web_service_id}`,
                    "Client Id": `${result.Client?.client_id}`,
                    "Client Name": `${result.Client?.name.replaceAll('"', '')}`,
                    Type: `${result.type.replaceAll('"', '')}`,
                    Name: `${result.name.replaceAll('"', '')}`,
                    "Billing Start Date": `"${result.billing_start_date ? date({ date: result.billing_start_date }) : 'No Date'}"`,
                    Expiry: `"${result.expiry ? date({ date: result.expiry }) : 'No Date'}"`,
                    Rate: `$${result.rate.replaceAll('"', '')}`,
                    Frequency: `${result.frequency.replaceAll('"', '')}`,
                    Primary: `${result.is_primary ? 'true' : 'false'}`,
                    Status: `${result.status.replaceAll('"', '')}`,
                }));
                downloadCsv(csvData, null, `WebServices_${Math.floor(new Date().getTime() / MS_PER_S)}`);
            }).catch(API.default_error_handler);
        }
    };
    useEffect(() => {
        if (persistBillingMonth) {
            API.get(generateWebServiceUrl(persistBillingMonth, persistFrequency, persistServiceType, persistSearchQuery, pageIndex, 10, orderBy)).then(response => {
                setResultCount(response.data.result_count);
                setWebServices(response?.data?.Web_Service ? response.data?.Web_Service : []);
            }).catch(API.default_error_handler);
        }
    }, [pageIndex, persistBillingMonth, persistFrequency, persistSearchQuery, persistServiceType, orderBy]);
    return (
        <CustomizeReportView>
            <ReportHeader generateOnClick={handleGenerate} csvOnClick={handleCSV} csvDisabled={!webServices || webServices.length === 0} generateDisabled={!billingMonth}/>
            <ReportToolbar>
                <ProjectSelectionView>
                    <ResponsiveFormDropdown
                        label='Billing Month'
                        containerStyle={{ marginRight: '6px' }}
                        defaultValue={0}
                        width='20%'
                        height='42px'
                        values={MONTH_VALUES}
                        options={MONTHS}
                        onChange={(e) => setBillingMonth(e.target.value)}
                    />
                    <ResponsiveFormDropdown
                        label='Frequency'
                        placeholder='-select-'
                        containerStyle={{ marginLeft: '6px', marginRight: '6px' }}
                        width='20%'
                        height='42px'
                        values={SERVICE_FREQUENCY_LIST}
                        options={SERVICE_FREQUENCY_LIST}
                        onChange={(e) => setFrequency(e.target.value)}
                    />
                    <ResponsiveFormDropdown
                        label='Service Type'
                        placeholder='-select-'
                        containerStyle={{ marginLeft: '6px', marginRight: '12px' }}
                        width='20%'
                        height='42px'
                        values={SERVICE_TYPE_LIST}
                        options={SERVICE_TYPE_LIST}
                        onChange={(e) => setServiceType(e.target.value)}
                    />
                    <SearchInput
                        label='Search'
                        height='42px'
                        containerStyle={{ flex: 1, minWidth: '200px', width: '100%' }}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        onSubmit={e => {
                            e.preventDefault();
                            handleGenerate();
                        }}
                    />
                </ProjectSelectionView>
                <PageIndex padding='0.25rem 0 0.25rem 1rem' resultCount={resultCount} currentPage={pageIndex} />
            </ReportToolbar>
            <FlexTableController rowPadding='0.5rem 1rem 0.5rem 1rem' tableData={SetupTableData(webServices)} sort={(e, title) => updateSorting(e,title, setOrderBy)}/>
            <PageController pageNumber={pageIndex} setPageNumber={setPageIndex} numPages={Math.ceil(resultCount / 10)} />
        </CustomizeReportView>
    );
};
