import React, {useState, useContext} from 'react';
import styled, {css} from 'styled-components';
import {
    Color,
    COMPLETED_DATE,
    FontSize,
    HOURS,
    PROJECT,
    SUBMITTED_DATE,
    TOTAL_HOURS,
    TYPE,
    USER,
    USER_READ_PERM,
    CLIENT_READ_PERM,
    PROJECT_READ_PERM,
    NAME,
    CONTACT_NAME,
    CONTACT_EMAIL,
    CREATED,
    JOB_NUMBER,
    CLIENT,
    PROJECT_TOTAL_PRICE
} from '../../constants';
import {StyledText} from '../Shared/StyledText';
import {StyledBase} from '../Shared/StyledBase';
import {Button} from '../Shared/Button';
// import {ProjectTimeSheet} from './ProjectTimeSheet';
// import {UserTimeSheet} from './UserTimeSheet';
import {UserTimeTotals} from './UserTimeTotals';
import {CompletedProjects} from './CompletedProjects';
import {ClientEmails} from './ClientEmails';
import { DataContext } from '../../data-context';
import { WebServicesReport } from './WebServicesReport';
import { Helmet } from 'react-helmet';
import { LargeOrder } from './LargeOrder';

// const PROJECT_TAB = 'project-time-sheet';
const USER_TAB = 'user-time-sheet';
const COMPLETED_PROJECTS_TAB = 'completed-projects';
const WEB_SERVICES_TAB = 'web-services';
const LARGE_ORDER_TAB = 'large-order';
const CONTACT_EMAILS_TAB = 'contact-emails';

const ReportsView = styled.div`
	margin: 32px 32px 32px 24px;
`;

const HeaderView = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 32px;
`;

const HeaderTitle = styled(StyledText)`
    color: ${Color.nile};
    font-size: ${FontSize.header2};
`;

const ReportsContentView = styled.div`
	${StyledBase}
    display: flex;
    flex-direction: column;
	background-color: ${Color.white};
	margin-bottom: 32px;
	border-radius: 3px 3px 0 0;
	box-shadow: 0rem 0.0625rem 0.375rem ${Color.nile15};
`;

const TabView = styled.div`
    width: 100%;
	border-bottom: 1px solid ${Color.nile10};
	margin: 0;
`;

const ReportsTab = styled(Button)`
	${(props) => css`
		color: ${props.selected ? Color.nile90 : Color.nile50};
		font-size: ${FontSize.header5};
		padding: 1.5rem 1.5rem;
		margin-top: -1px;
		border-width: 0px;
		border-top: ${props.selected ? '0.25rem solid' + Color.fuel : 'none'};
		border-radius: 0px;
	`}
`;

export const updateSorting = (e, title, setter) => {
    setter(updateOrderBy(e, title));
    e.currentTarget.dataset.sortorder = e.currentTarget.dataset.sortorder === 'asc' ? 'desc' : 'asc';
}

const updateOrderBy = (e, title) => {
    switch(title){
        case USER:
            return "order_by=user_name:" + e.currentTarget.dataset.sortorder;
        case PROJECT:
            return "order_by=project_name:" + e.currentTarget.dataset.sortorder;
        case HOURS:
            return "order_by=duration_mins:" + e.currentTarget.dataset.sortorder;
        case SUBMITTED_DATE:
            return "order_by=entered_at:" + e.currentTarget.dataset.sortorder;
        case COMPLETED_DATE:
            return "order_by=status_set_at:" + e.currentTarget.dataset.sortorder;
        case TOTAL_HOURS:
            return "order_by=hours:" + e.currentTarget.dataset.sortorder;
        case TYPE:
            return "order_by=project_type:" + e.currentTarget.dataset.sortorder;
        case NAME:
        case CONTACT_NAME:
            return "order_by=name:" + e.currentTarget.dataset.sortorder;
        case CONTACT_EMAIL:
            return "order_by=email:" + e.currentTarget.dataset.sortorder;
        case CREATED:
            return "order_by=created_at:" + e.currentTarget.dataset.sortorder;
        case JOB_NUMBER:
            return "order_by=project_id:" + e.currentTarget.dataset.sortorder;
        case CLIENT:
            return "order_by=client_name:" + e.currentTarget.dataset.sortorder;
        case PROJECT_TOTAL_PRICE:
            return "order_by=calculated_total_price:" + e.currentTarget.dataset.sortorder;
        default: return  "";
    }
}

export const Reports = (props) => {
    const { userPerms } = useContext(DataContext);
    const [currentTab, setCurrentTab] = useState(userPerms[PROJECT_READ_PERM] ? USER_TAB : null);
    return (
        <ReportsView {...props}>
            <Helmet>
				<meta name="description" content='Reports' />
			</Helmet>
            <HeaderView>
                <HeaderTitle as='h1'>Reports</HeaderTitle>
            </HeaderView>
            <ReportsContentView>
                <TabView>
                    {/* {
                        userPerms[PROJECT_READ_PERM] &&
                        <ReportsTab
                            transitionTime='0'
                            title='Project Time Sheet'
                            selected={currentTab === PROJECT_TAB}
                            onClick={() => setCurrentTab(PROJECT_TAB)}
                        />
                    } */}
                    {
                        userPerms[USER_READ_PERM] &&
                        <ReportsTab
                            transitionTime='0'
                            title='User Time Sheet'
                            selected={currentTab === USER_TAB}
                            onClick={() => setCurrentTab(USER_TAB)}
                        />
                    }
                    {
                        userPerms[PROJECT_READ_PERM] &&
                        <ReportsTab
                            transitionTime='0'
                            title='Completed Projects'
                            selected={currentTab === COMPLETED_PROJECTS_TAB}
                            onClick={() => setCurrentTab(COMPLETED_PROJECTS_TAB)}
                        />
                    }
                    {
                        userPerms[PROJECT_READ_PERM] &&
                        <ReportsTab
                            transitionTime='0'
                            title='Large Order'
                            selected={currentTab === LARGE_ORDER_TAB}
                            onClick={() => setCurrentTab(LARGE_ORDER_TAB)}
                        />
                    }
                    {
                        userPerms[CLIENT_READ_PERM] &&
                        <ReportsTab
                            transitionTime='0'
                            title='Client Emails'
                            selected={currentTab === CONTACT_EMAILS_TAB}
                            onClick={() => setCurrentTab(CONTACT_EMAILS_TAB)}
                        />
                    }
                    {
                        userPerms[CLIENT_READ_PERM] &&
                        <ReportsTab
                            transitionTime='0'
                            title='Web Services'
                            selected={currentTab === WEB_SERVICES_TAB}
                            onClick={() => setCurrentTab(WEB_SERVICES_TAB)}
                        />
                    }
                </TabView>
                {
                    // (currentTab === PROJECT_TAB && <ProjectTimeSheet />)
                    // ||
                    // (currentTab === USER_TAB && <UserTimeSheet />)
                    (currentTab === USER_TAB && <UserTimeTotals />)
                    ||
                    (currentTab === COMPLETED_PROJECTS_TAB && <CompletedProjects />)
                    ||
                    (currentTab === LARGE_ORDER_TAB && <LargeOrder />)
                    ||
                    (currentTab === CONTACT_EMAILS_TAB && <ClientEmails />)
                    ||
                    (currentTab === WEB_SERVICES_TAB && <WebServicesReport />)
                    ||
                    'You are not authorized to view this'
                }
            </ReportsContentView>
        </ReportsView>
    );
};
