import React, {useContext} from 'react';
import styled from 'styled-components';
import {
	Color,
	NAV_CLIENTS,
	NAV_DASHBOARD,
	NAV_DASHBOARD_SALES,
	NAV_LOGIN,
	NAV_OVERVIEW,
	NAV_PROJECTS,
	NAV_QUOTES,
	NAV_REPORTS,
	NAV_USERS,
	VIEW_ACTIVE_PROJ_PERM,
	VIEW_FULL_DASH_PERM,
	VIEW_LEADS_PERM,
	USER_READ_PERM,
	VIEW_PROJECTS_PERM,
	VIEW_QUOTES_PERM,
	VIEW_CLIENTS_PERM,
	VIEW_REPORTS_PERM,
} from '../../constants';
import {Image} from '../Shared/Image';
import {LeftMenuButton} from '../Shared/Button';
import {DataContext} from '../../data-context';
import {clearCookies} from '../../react-utils/src/helperfunctions';
import {useHistory, useLocation} from 'react-router-dom';
import logo from '../../assets/side-nav-menu-logo.png';
import {
	faAddressBook,
	faChartLine,
	faFolder,
	faHome,
	faMoneyCheckAlt,
	faSignOutAlt,
	faUser,
	faUsers,
} from '@fortawesome/pro-regular-svg-icons';

const SideNavListView = styled.div`
	overflow-y: scroll;
	height: 100vh;
	text-align: center;
	box-shadow: 4px 0 4px -4px ${Color.nile15};
`;


export const SideNavList = props => {
	const { setSessionId, setUserId, userPerms } = useContext(DataContext);
	const history = useHistory();
	const location = useLocation();
	const Logout = () => {
		setSessionId('');
		setUserId('');
		clearCookies();
		window.localStorage.clear();
		history.push(NAV_LOGIN);
	}
	return (
		<SideNavListView {...props}>
			<Image
				marginTop='24px'
				marginBottom='40px'
				height='115.5px'
				width='208.5px'
				src={logo}
			/>
			{
				userPerms[VIEW_ACTIVE_PROJ_PERM] &&
				<LeftMenuButton
					title={'Dashboard'}
					icon={faHome}
					onClick={() => {
						props.history.push(NAV_DASHBOARD);
					}}
					selected={location.pathname === (NAV_DASHBOARD)}
				/>
			}		
			{
				userPerms[VIEW_FULL_DASH_PERM] &&
				<LeftMenuButton
					title={'Overview'}
					iconSize='lg'
					icon={['fac', 'FolderCheck']}
					onClick={() => {
						props.history.push(NAV_OVERVIEW);
					}}
					selected={location.pathname === (NAV_OVERVIEW)}
				/>
			}
			{
				userPerms[VIEW_LEADS_PERM] &&
				<LeftMenuButton
					title={'Leads'}
					icon={faUsers}
					onClick={() => {
						props.history.push(NAV_DASHBOARD_SALES);
					}}
					selected={location.pathname.includes(NAV_DASHBOARD_SALES)}
				/>
			}
			{
				userPerms[VIEW_PROJECTS_PERM] &&
				<LeftMenuButton
					title={'Projects'}
					icon={faFolder}
					onClick={() => {
						props.history.push(NAV_PROJECTS);
					}}
					selected={location.pathname.includes(NAV_PROJECTS)}
				/>
			}
			{
				userPerms[VIEW_QUOTES_PERM] &&
				<LeftMenuButton
					title={'Quotes'}
					icon={faMoneyCheckAlt}
					onClick={() => {
						props.history.push(NAV_QUOTES);
					}}
					selected={location.pathname.includes(NAV_QUOTES)}
				/>
			}
			{
				userPerms[VIEW_CLIENTS_PERM] &&
				<LeftMenuButton
					title={'Clients'}
					icon={faAddressBook}
					onClick={() => {
						props.history.push(NAV_CLIENTS);
					}}
					selected={location.pathname.includes(NAV_CLIENTS)}
				/>
			}
			{
				userPerms[VIEW_REPORTS_PERM] &&
				<LeftMenuButton
					title={'Reports'}
					icon={faChartLine}
					onClick={() => {
						props.history.push(NAV_REPORTS);
					}}
					selected={location.pathname.includes(NAV_REPORTS)}
				/>
			}
			{
				userPerms[USER_READ_PERM] &&
				<LeftMenuButton
					title={'User Managers'}
					icon={faUser}
					onClick={() => {
						props.history.push(NAV_USERS);
					}}
					selected={location.pathname.includes(NAV_USERS)}
				/>
			}
			<LeftMenuButton
				title={'Logout'}
				icon={faSignOutAlt}
				onClick={() => Logout()}
				selected={false}
			/>
		</SideNavListView>
	);
};

SideNavList.defaultProps = {
	className: 'SideNavList'
};
