import React, {useContext} from 'react';
import styled, {css} from 'styled-components';
import {
	Color,
	NAV_CLIENTS,
	NAV_DASHBOARD,
	NAV_DASHBOARD_SALES,
	NAV_KITCHEN_SINK,
	NAV_LOGIN,
	NAV_OVERVIEW,
	NAV_PROJECTS,
	NAV_QUOTES,
	NAV_REPORTS,
	NAV_RESET_PASSWORD,
	NAV_USERS,
	VIEW_FULL_DASH_PERM,
	VIEW_LEADS_PERM,
	VIEW_ACTIVE_PROJ_PERM,
	VIEW_PROJECTS_PERM,
	VIEW_QUOTES_PERM,
	VIEW_CLIENTS_PERM,
	VIEW_REPORTS_PERM,
	VIEW_USER_MANAGER_PERM
} from '../../constants';
import {MenuList} from './MenuList';
import {SideNavList} from './SideNavList';
import {Route, Switch, useLocation} from 'react-router-dom';
import {Dashboard} from '../Dashboards/Dashboard';
import {Projects} from '../Projects/Projects';
import {Users} from '../Users/Users';
import {UserDetails} from '../Users/UserDetails';
import {Clients} from '../Clients/Clients';
import {ClientDetails} from '../Clients/ClientDetails';
import {Quotes} from '../Quotes/Quotes';
import {ProjectDetails} from '../ProjectDetails/ProjectDetails';
import {KitchenSink} from '../Shared/KitchenSink';
import {breakpoint} from '../../breakpoints';
import {DashboardSales} from '../Dashboards/DashboardSales';
import {DataContext} from '../../data-context';
import {LoginController} from '../Login/LoginController';
import {PrivateRoute} from '../Login/PrivateRoute';
import {Reports} from '../Reports/Reports';
import {Groups} from '../Users/Groups';
import {MobileNav} from './MobileNav';

export const AppViewContainer = styled.div`
	display: flex;
	flex-direction: column;
	${breakpoint('large')} {
		flex-direction: row;
	}
`;

const SideMenuInner = styled.div`
	display: flex;
	flex-flow: row nowrap;
	height: 100vh;
	z-index: 1;
	position: fixed;
	width: 322px;
`;

const SideMenu = styled.div`
	width: 322px;
	display: none;

	${breakpoint('large')} {
		display: block;
	}
`;

const MobileMenu = styled.div`
	width: 100%;
	display: block;
	position: fixed;
	z-index: 1;
	${breakpoint('large')} {
		display: none;
	}
`;

const PageContentWrapper = styled.div`
	background-color: ${Color.athens};
	width: 100%;
	min-height: 100vh;
	${props => css`
		padding-top: ${props.isAuthorized ? '64px' : '0'};
	`}
	${breakpoint('large')} {
		padding-top: unset;
		width: calc(100% - 322px);
		${props => css`
			overflow:${props.isAuthorized ? 'auto' : 'visible'};
			height:${props.isAuthorized ? '100vh' : 'auto'};
		`}
	}
`;

export const AppView = (props) => {
	const context = useContext(DataContext);
	const location = useLocation()
	const isAuthorized = !!context?.userSessionId;
	return (
		<AppViewContainer>
			{
				(isAuthorized && !location.pathname.includes('reset')) &&
				<SideMenu>
					<SideMenuInner>
						<MenuList/>
						<SideNavList {...props} />
					</SideMenuInner>
				</SideMenu>
			}
			{
				(isAuthorized && !location.pathname.includes('reset')) &&
				<MobileMenu>
					<MobileNav />
				</MobileMenu>
			}
			<PageContentWrapper isAuthorized={isAuthorized}>
				<Switch>
					{
						!isAuthorized &&
						<Route
							path={NAV_LOGIN}
							render={() => <LoginController {...props} />}
						/>
					}
					<Route
						path={NAV_RESET_PASSWORD}
						render={() => <LoginController {...props} />}
					/>
					{
						context.userPerms[VIEW_FULL_DASH_PERM] &&
						<PrivateRoute
							path={NAV_OVERVIEW}
							render={() => <Dashboard key='overview' {...props} />}
							isAuthorized={isAuthorized}
						/>
					}
					{
						context.userPerms[VIEW_LEADS_PERM] &&
						<PrivateRoute
							path={NAV_DASHBOARD_SALES}
							render={() => <DashboardSales {...props}/>}
							isAuthorized={isAuthorized}
						/>
					}
					{
						context.userPerms[VIEW_ACTIVE_PROJ_PERM] &&
						<PrivateRoute
							path={NAV_DASHBOARD}
							render={() => <Dashboard key='dashboard' {...props} userId={context.userId}/>}
							isAuthorized={isAuthorized}
						/>
					}

					{
						context.userPerms[VIEW_PROJECTS_PERM] &&
						<PrivateRoute
							path={NAV_PROJECTS}
							exact
							render={() => <Projects {...props}/>}
							isAuthorized={isAuthorized}
						/>
					}
					{
						context.userPerms[VIEW_PROJECTS_PERM] &&
						<PrivateRoute
							path={`${NAV_PROJECTS}/:project_id`}
							render={(_props) => (
								<ProjectDetails
									{...props}
									project_id={_props.match.params.project_id}
									key={_props.match.params.project_id}
								/>
							)}
							isAuthorized={isAuthorized}
						/>
					}
					{
						context.userPerms[VIEW_QUOTES_PERM] &&
						<PrivateRoute
							path={NAV_QUOTES}
							exact
							render={() => <Quotes {...props} />}
							isAuthorized={isAuthorized}
						/>
					}
					{
						context.userPerms[VIEW_QUOTES_PERM] &&
						<PrivateRoute
							path={`${NAV_QUOTES}/:project_id`}
							render={(_props) => (
								<ProjectDetails
									{...props}
									project_id={_props.match.params.project_id}
									key={_props.match.params.project_id}
									isQuote={true}
								/>
							)}
							isAuthorized={isAuthorized}
						/>
					}
					{
						context.userPerms[VIEW_CLIENTS_PERM] &&
						<PrivateRoute
							path={NAV_CLIENTS}
							exact
							render={() => <Clients {...props} />}
							isAuthorized={isAuthorized}
						/>
					}
					{
						context.userPerms[VIEW_CLIENTS_PERM] &&
						<PrivateRoute
							path={`${NAV_CLIENTS}/:client_id`}
							render={(_props) => (
								<ClientDetails
									{...props}
									client_id={Number(_props.match.params.client_id)}
									key={_props.match.params.client_id}
								/>
							)}
							isAuthorized={isAuthorized}
						/>
					}
					{
						context.userPerms[VIEW_REPORTS_PERM] &&
						<PrivateRoute
							path={NAV_REPORTS}
							render={() => <Reports {...props} />}
							isAuthorized={isAuthorized}
						/>
					}
					{
						context.userPerms[VIEW_USER_MANAGER_PERM] &&
						<PrivateRoute
							path={NAV_USERS}
							exact
							render={() => <Users {...props} />}
							isAuthorized={isAuthorized}
						/>
					}
					{
						context.userPerms[VIEW_USER_MANAGER_PERM] &&
						<PrivateRoute
							path={`${NAV_USERS}/groups`}
							exact
							render={() => <Groups />}
							isAuthorized={isAuthorized}
						/>
					}
					{
						context.userPerms[VIEW_USER_MANAGER_PERM] &&
						<PrivateRoute
							path={`${NAV_USERS}/:user_id`}
							render={(_props) => (
								<UserDetails
									{...props}
									user_id={_props.match.params.user_id}
									key={_props.match.params.user_id}
								/>
							)}
							isAuthorized={isAuthorized}
						/>
					}
					<PrivateRoute
						path={'/'}
						render={(_props) => (null)}
						isAuthorized={isAuthorized}
					/>
					<PrivateRoute
						path={NAV_KITCHEN_SINK}
						render={() => <KitchenSink {...props} />}
						isAuthorized={isAuthorized}
					/>
				</Switch>
			</PageContentWrapper>
		</AppViewContainer>
	);
};

AppViewContainer.defaultProps = {
	className: 'AppViewContainer',
};

PageContentWrapper.defaultProps = {
	className: 'PageContentWrapper',
};

SideMenu.defaultProps = {
	className: 'SideMenu',
};
