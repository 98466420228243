import {parse_cookies} from "./cookie-management";
import {
	DESIGN_ONLY,
	MARKETING,
	OLD_TYPE,
	PRINT,
	PROMOTIONAL_ITEMS,
	SESSION_ID,
	SIGNAGE,
	VEHICLE_DECALS,
	WEARABLES,
	WEB
} from "../constants";

export function remCalc(px, rootFontSize = 16) {
	return `${Math.round(px / Math.max(1, rootFontSize))}rem`;
}

export function removeHtmlTags(text) {
	return text.replace(/(<([^>]+)>)/ig,'');
};

export function getInitials(text, numberOfLetters) {
	var initials = '-';
	if (text?.length > 0) {
		initials = text
			.replace('-', ' ')
			.split(' ')
			.reduce(
				(result, currentWord) =>
					result + currentWord.charAt(0).toUpperCase(),
				''
			);
		initials = initials.substring(0, numberOfLetters);
	}
	return initials;
}

export function orderTypes(types, includeOldType = false) {
	if(types){
		let proj_types=[DESIGN_ONLY,PRINT,SIGNAGE,WEB,VEHICLE_DECALS,PROMOTIONAL_ITEMS,WEARABLES,MARKETING].filter(type => {
			return types.some(givenType => givenType === type);
		});
		types.forEach(type => {
			if((includeOldType ? true : type !== OLD_TYPE) && !proj_types.includes(type)){
				proj_types.push(type);
			}
		});

		return proj_types;
	}
	return [];
}

//get the human-readable "pretty name" for a status based on its slug value
export function formatText(stsName) {
	switch (stsName) {
		case '':
		case undefined:
			return 'No Status Set';
		default:
			//if no explicit handling is given
			//then convert by replacing - with space
			//and then applying titlecase (capitalize the first letter of each word)
			stsName = stsName.replace('-', ' ');
			return stsName.replace(
				/\w\S*/g,
				(txt) =>
					txt.charAt(0).toUpperCase() +
					txt.substr(1, txt.length - 1).toLowerCase()
			);
	};
};

export function isAFunction(func) {
	return func && typeof func === 'function';
}

export function getTodaysDate(contextDate) {
	const now = contextDate ? contextDate : new Date();
	return new Date(now.getFullYear(), now.getMonth(), now.getDate());
}

export function getTomorrowsDate(contextDate) {
	const now = contextDate ? contextDate : new Date();
	return new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
}

export function getNextWeeksDate(contextDate) {
	const now = contextDate ? contextDate : new Date();
	return new Date(now.getFullYear(), now.getMonth(), now.getDate() + 7);
}

export function getNextMonthsDate(contextDate) {
	const now = contextDate ? contextDate : new Date();
	if (now.getMonth() === 11) {
		return new Date(now.getFullYear() + 1, 0, 1);
	} else {
		return new Date(now.getFullYear(), now.getMonth() + 1, 1);
	}
}

export function convertMinutesToHours(minutes) {
	return displayRounded(minutes / 60, 2);
}

export function getHeaders(content_type = 'application/json') {
	const cookies = parse_cookies();
	return { 'Content-Type': content_type, Authorization: cookies[SESSION_ID] };
}

export function displayRounded(num,digits){
	let formattedNum=num.toFixed(digits);
	let trailingChars=['0','.']
	while(trailingChars.includes(formattedNum.charAt(formattedNum.length-1))){
		formattedNum=formattedNum.substring(0,formattedNum.length-1);
	}
	return formattedNum;
}

